<template lang="html">
  <span class="Badge">{{ formattedCount }}</span>
</template>

<script>
export default {
  name: 'Badge',

  props: {
    count: {
      type: [Number, String],
    },
  },

  computed: {
    formattedCount: function () {
      return this.count < 10 ? this.count : '+9';
    },
  },
}
</script>

<style scoped lang="scss">
.Badge {
  background-color: var(--primary-color);
  width: 22px;
  height: 22px;
  display: flex;
  border-radius: 50%;
  font-weight: bold;
  color: #fff;
  justify-content: center;
  font-size: 10px;
  flex-direction: column;
  text-align: center;
  border: 2px solid #fff;
}
</style>
